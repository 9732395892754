* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;

  overflow-x: hidden;
}

/* CSS for Navbar */
.navbar {
  font-family: "Times New Roman", Times, serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0px 8px 6px -6px gray;
  border-bottom: 0.3px gray;
}
.active {
  border-bottom: 2px solid black;
}
.navbar-brand {
  font-weight: 700px;
  letter-spacing: 3px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
/* ==========================Footer============================= */
.footer {
  color: white;
  padding: 25px;
  background-color: rgb(189, 187, 187);
}
.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}
.footer a:hover {
  color: #0c0c0c;
  border-bottom: 1px solid grey;
}
/* ===============================================Page Not Found ================================ */
/* Set the CSs correctly and look for Margin and Padding */

.pnf-div {
  margin-left: 120px;
  margin-right: 100px;
  margin-top: 100px;
  margin-bottom: 0px;
  border: 1mm solid grey;
  padding: 50px 0;
  width: calc(100% - 260px); /* Adjust the width based on margins */
  height: 250px;
}

.pnf-div h5 {
  margin-bottom: 20px; /* Adjust margin as needed */
}

/* ==========================Register======================== */
.register {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

/* ========================  Product Link======================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}
